import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { Link } from 'gatsby';
import Image from '../components/Image';
import Icon from '../components/Icon';
import OutboundLink from '../components/OutboundLink';
import Modal from '../components/Modal';
import Button from '../components/Button';

const IndexPage = () => (
  <Layout title="Home">
    {/* HOMEPAGE HERO */}
    <Section className="hero-container">
      <Container>
        <Row>
          <Column size={12} className="hero">
            <div className="home-hero__overlay"></div>
            <h1 className="home-hero__title">
              <Image
                filename="hp-hero-ee-logo.png"
                alt="Econ Essentials powered by Future Fundamentals"
              />
            </h1>
            <video
              className="home-hero__video"
              autoPlay={true}
              muted={true}
              loop={true}
              title="CME Intro Video"
              playsInline={true}
              controls={false}
              preload="auto"
            >
              <source
                src="/images/hp-hero-bg-video.mp4"
                type="video/mp4"
              ></source>
            </video>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* HOMEPAGE INTRO */}
    <Section className="home-intro">
      <Container>
        <Row>
          <Column size={9}>
            <h2>
              Interactive Resources to Connect Core Economic Principles to
              Everyday Life
            </h2>
            <p>
              Econ Essentials introduces students to the basics of economics and
              financial principles through timely real-world connections and
              hands-on learning, all brought to life by the industry-leading
              insights of Futures Fundamentals. A growing collection of videos,
              collaborative activities, self-guided learning modules and more,
              the site provides standards-aligned instructional materials with a
              wide variety of interdisciplinary curriculum connections.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO VIEWPOINT SERIES */}
    {/* <Section className="video-series">
      <Container>
        <Row>
          <Column size={6} className="left">
            <div className="label">Featured Content</div>
            <h2>Make Real Classroom to Career Connections </h2>
            <h3>
            Hear from young professionals applying core economic principles in the working world and inspire students to explore similar pathways through interactive, evidence-based resources.
            </h3>
            <p>Celebrate Financial Literacy Month in Grades 9-12 </p>
            <p>
            Research shows that students and parents are looking to educators to increase financial literacy rates. April is Financial Literacy Month – help your high schoolers improve their understanding of finance within the larger context of financial markets by making real-world connections through our latest digital lesson bundles and special release video series, designed to engage high school classes.
            </p>
            <Modal className="video-modal"
              trigger={
                <a className="button mt-1 mr-1">
                  Learn More <Icon name="caratright" />
                </a>
              }
              video={true}
            >
                <div className='video'>
                    <iframe
                        src='https://app.discoveryeducation.com/learn/embedded-player/f8c231cc-408b-4c6d-83bf-c58b90e030e8?utm_source=microsite&utm_medium=embed'
                        title='Simplify the Complexity of Finance'
                        width="560"
                        height="349"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </Modal>
            <OutboundLink to="/financial-markets" className="button mt-1 mr-1">
            Explore Resources <Icon name="caratright" />
            </OutboundLink>
          </Column>
          <Column size={6} className="right">
            <Image
              filename="featureRowImage-FinLitDay2024.jpg"
              alt="Classroom-Ready Lessons"
              style={{ height: '100%' }}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO VIEWPOINT SERIES */}
    <Section className="video-series">
      <Container>
        <Row>
          <Column size={6} className="left blue-box">
            <h2>NEW Classroom-Ready Lessons</h2>
            <h3>
              Open your students' eyes to another facet of the global economy by
              building their understanding of futures markets and how they
              impact our daily lives.
            </h3>
            <p>
              <b>Topic:</b> Futures Markets | <b>Grades:</b> 9-12
            </p>
            <p>
              This 2-lesson bundle focuses on futures contracts, risk
              management, price fluctuation mitigation, and how to make informed
              trading decisions while highlighting the pivotal roles of hedgers
              and speculators, how they bring balance to the marketplace, and
              the essential role futures play in the global economy.
            </p>
            <Link
              to="/financial-markets#guides"
              className="button mt-1 mr-1"
              style={{ maxWidth: 'fit-content' }}
            >
              Learn More <Icon name="caratright" />
            </Link>
          </Column>
          <Column size={6} className="right">
            <Image
              filename="featureRowImage-MiniDLBs-Desktop@2x.jpg"
              alt="Classroom-Ready Lessons"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* ECON ESSENTIALS IN (AND OUT) OF THE CLASSROOM */}
    <Section className="ee-classroom">
      <Container>
        <Row>
          <Column size={10}>
            <h2>Econ Essentials in (and out) of the Classroom </h2>
            <p>
              Econ Essentials offers a number of resources that complement a
              wide variety of educational settings and topic areas. Explore some
              of the many ways Econ Essentials fits into your classroom and
              start applying the materials today!
            </p>
          </Column>
        </Row>
        <Row>
          {/* TABBED GALLERY */}
          <Column size={10} className="ee-tabber">
            <ul className="tabs">
              <li>
                <input type="radio" defaultChecked name="tabs" id="tab1" />
                <label htmlFor="tab1">Social Studies</label>
                <div
                  id="tab-content1"
                  className="tab-content social-studies animated fadeIn"
                >
                  <Image filename="img-hp-carousel-1-socialStudies.png" />
                  <p>
                    The core economic principles of food, fuel and finance are a
                    natural fit with social studies instruction. Econ Essentials
                    resources focusing on these topics can be embedded in
                    existing units or used to construct new ones.
                  </p>
                  <OutboundLink
                    to="/pdfs/1_intro/CME-EconEssentials-Standards-SS-Correlation-FINAL.pdf"
                    className="button inverse mt-1"
                  >
                    Learn More <Icon name="caratright" />
                  </OutboundLink>
                </div>
              </li>
              <li>
                <input type="radio" name="tabs" id="tab2" />
                <label htmlFor="tab2">Career and Tech Ed</label>
                <div
                  id="tab-content2"
                  className="tab-content teched animated fadeIn"
                >
                  <Image filename="img-hp-carousel-2-careerAndTechEd.png" />
                  <p>
                    Students in business, agriculture, and family and consumer
                    science programs can benefit from Econ Essentials resources.
                    See how they connect to educational standards and where
                    there are connections for student organizations, like FFA,
                    FBLA, DECA, and FCCLA.
                  </p>
                  <OutboundLink
                    to="/pdfs/1_intro/CME-EconEssentials-CTERoadMap.pdf"
                    className="button inverse mt-1"
                  >
                    Learn More <Icon name="caratright" />
                  </OutboundLink>
                </div>
              </li>
              <li>
                <input type="radio" name="tabs" id="tab3" />
                <label htmlFor="tab3">Financial Literacy</label>
                <div
                  id="tab-content3"
                  className="tab-content financial-literacy animated fadeIn"
                >
                  <Image filename="img-hp-carousel-3-financialLiteracy.png" />
                  <p>
                    Add depth to personal finance lessons with Econ Essentials
                    resources. Check out the standards correlation to see which
                    resources align with the National Standards for Personal
                    Financial Education.
                  </p>
                  <OutboundLink
                    to="/pdfs/1_intro/CME-EconEssentials-Standards-PersFin-Correlation-FINAL.pdf"
                    className="button inverse mt-1"
                  >
                    Learn More <Icon name="caratright" />
                  </OutboundLink>
                </div>
              </li>
              <li>
                <input type="radio" name="tabs" id="tab4" />
                <label htmlFor="tab4">Investing Clubs</label>
                <div
                  id="tab-content4"
                  className="tab-content investing-clubs animated fadeIn"
                >
                  <Image filename="img-hp-carousel-4-investingClubs.png" />
                  <p>
                    Enhance your afterschool investing club lessons with Econ
                    Essentials resources. Check out the road map for ideas.
                  </p>
                  <OutboundLink
                    to="/pdfs/1_intro/CME-EconEssentials-InvestmentClubRoadMap.pdf"
                    className="button inverse mt-1"
                  >
                    Learn More <Icon name="caratright" />
                  </OutboundLink>
                </div>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* FUTURES FUNDAMENTALS */}
    <Section className="futures-fundamentals bb-orange">
      <Container>
        <Row>
          <Column size={6} className="left">
            <h2>Futures Fundamentals</h2>
            <h3>Simplifying the Complexities of the Global Marketplace</h3>
            <p>
              Take your understanding of core economic and financial principles
              to the next level with Futures Fundamentals, the program that
              powers Econ Essentials. Futures Fundamentals is a one-stop
              educational resource designed to simplify and explain complex
              market topics, including those explored within the materials
              available on Econ Essentials. Continue your learning journey today
              with additional resources from Futures Fundamentals and discover
              how futures play an essential role in the world around us.
            </p>
            <OutboundLink
              to="https://www.futuresfundamentals.org/"
              className="button mt-1"
            >
              Learn More
            </OutboundLink>
          </Column>
          <Column size={6} className="right">
            <Image filename="hp-futures-fundamentals.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SIGNUP FOR UPDATES */}
    <Section className="signup-updates">
      <Container>
        <Row>
          <Column size={7}>
            <h2>Sign Up for Updates</h2>
            <p>
              Want to be among the first to learn about exciting new resources
              and upcoming learning opportunities? Stay connected with Econ
              Essentials and sign up to receive updates today!
            </p>
            <OutboundLink
              to="https://info.discoveryeducation.com/Econ-Essentials-Powered-by-Futures-Fundamentals_Opt-Ins.html"
              className="button mt-1"
            >
              Learn More <Icon name="caratright" />
            </OutboundLink>
          </Column>
          <Column size={4} offset={1}>
            <Image filename="hp-signip-map.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* PURE CSS STANDARDS-ALIGNED RESOURCES POPUP |
        THIS SHOULD USE THE MODAL COMPONENT */}
    <div id="sar-modal" className="overlay">
      <div className="sar">
        <a className="close" href="#">
          &times;
        </a>
        <h2 className="sar__heading">Standards-Aligned Resources</h2>
        <p>
          Download the standards below to reference the corresponding resource
          and standards connection.
        </p>
        <ul className="sar__resources">
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Correlation-OverviewChart.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Overview of National Standards
              Correlations
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Voluntary-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Voluntary National Content Standards in
              Economics
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-PersFin-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Personal Financial
              Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-SS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> C3 Framework for Social Studies State
              Standards
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-FCS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Family and
              Consumer Sciences Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Business-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Business Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-CT-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Common Career Technical Core
            </OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
